import { Button, Col, Row } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../common/components/Loader/Loader'
import './LoginPage.less'

import useWindowSize from '../../hooks/useWindowSize'
import LoginForm from './components/LoginForm/LoginForm'
import RegistrationModal from './components/RegistrationModal/RegistrationModal'
import RestorePasswordModal from './components/RestorePasswordModal/RestorePasswordModal'

import mainLogo from '../../assets/main-logo.svg'
import { clearMessages } from '../../redux/ducks/messages'
import { clearUserData } from '../../redux/ducks/user'

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL

const LoginPage = () => {
	const { width } = useWindowSize()
	const [colSizes, setColSizes] = useState({ span: 6, offset: 14 })
	const [visible, setVisible] = useState({
		register: false,
		restore: false,
		agreement: false,
		policy: false
	})
	const [isLoading, setIsLoading] = useState(true)

	const config = useSelector(state => state.config)
	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false)
		}, 100)
	}, [])

	useEffect(() => {
		dispatch(clearUserData())
		dispatch(clearMessages())
	}, [dispatch])

	useEffect(() => {
		if (width > 1180) {
			if (colSizes.span !== 6) {
				setColSizes({ span: 6, offset: 14 })
			}
		} else if (width > 950 && width <= 1180) {
			if (colSizes.span !== 8) {
				setColSizes({ span: 8, offset: 12 })
			}
		} else if (width > 768 && width <= 950) {
			if (colSizes.span !== 9) {
				setColSizes({ span: 9, offset: 12 })
			}
		} else {
			if (colSizes.span !== 24) {
				setColSizes({ span: 24, offset: 0 })
			}
		}
	}, [width, colSizes.span])

	return (
		<>
			<Loader isLoading={isLoading} />
			<div
				className={
					isLoading ? 'login-wrapper login-wrapper-none' : 'login-wrapper'
				}
				style={
					config && config.background
						? { backgroundImage: `url(${BASE_API_PATH}${config.background})` }
						: {}
				}
			>
				<Row>
					<Col
						span={colSizes.span}
						offset={colSizes.offset}
						className='login-column'
					>
						<div className='login-content-wrapper'>
							{config.displayed.show_logo && (
								<div className='login-content__logo-holder'>
									<img src={mainLogo} alt='main-logo' />
								</div>
							)}
							<div className='login-content'>
								<h1>Вход в личный кабинет</h1>
								{/* <h1>Личный кабинет пайщика КПКГ "Содействие плюс"</h1> */}
								<LoginForm
									visible={visible}
									esia_url={
										config.auth && config.auth.esia && config.auth.esia.url
											? config.auth.esia.url
											: null
									}
								/>
								{/* {config.auth && config.auth.esia && config.auth.esia.url &&
                  <Button
                    type='primary'
                  >
                  <a href={config.auth.esia.url} rel='noopener noreferrer'>
                    Войти через госуслуги (ЕСИА)
                  </a>
                  </Button>
                } */}
								<Button
									type='link'
									className='register-btn'
									onClick={() => {
										setVisible(v => {
											return { ...v, register: true }
										})
									}}
								>
									Зарегистрироваться
								</Button>
								<div className='login-forget-holder'>
									<p>Забыли пароль?</p>
									<Button
										type='link'
										onClick={() => {
											setVisible(v => {
												return { ...v, restore: true }
											})
										}}
									>
										Сбросить пароль
									</Button>
								</div>
								{(config.displayed.show_privacy ||
									config.displayed.show_user_agreement) && (
									<div className='login-forget-holder login-forget-holder_policy'>
										{config.displayed.show_privacy && (
											<Button
												type='link'
												onClick={() =>
													window.open(
														config['1c_configs'].return.ГлавныеНастройки
															.СсылкаСоглашенияОПД
													)
												}
											>
												Политика конфиденциальности
											</Button>
										)}
										{config.displayed.show_user_agreement && (
											<Button
												type='link'
												onClick={() =>
													window.open(
														config['1c_configs'].return.ГлавныеНастройки
															.СсылкаСоглашенияПЭП
													)
												}
											>
												Пользовательское соглашение
											</Button>
										)}
									</div>
								)}
								{/* Modals */}
								<RegistrationModal
									visible={visible.register}
									onCancel={() => {
										setVisible(v => {
											return { ...v, register: false }
										})
									}}
								/>

								<RestorePasswordModal
									visible={visible.restore}
									onCancel={() => {
										setVisible(v => {
											return { ...v, restore: false }
										})
									}}
								/>

								{/*<PolicyModal*/}
								{/*  visible={visible.policy}*/}
								{/*  onCancel={() => {*/}
								{/*    setVisible((v) => {*/}
								{/*      return { ...v, policy: false };*/}
								{/*    });*/}
								{/*  }}*/}
								{/*/>*/}

								{/*<AgreementModal*/}
								{/*  visible={visible.agreement}*/}
								{/*  onCancel={() => {*/}
								{/*    setVisible((v) => {*/}
								{/*      return { ...v, agreement: false };*/}
								{/*    });*/}
								{/*  }}*/}
								{/*/>*/}
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default memo(LoginPage)
